import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql, Link } from "gatsby"
import styled from "@emotion/styled"
// import Avatar from '@material-ui/core/Avatar';
import Headshot from '../images/carl-headshot-clipped.png'

const Container = styled.div`
  text-align: center;
`

const OuterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 78vh;
`

const Description = styled.p`
  padding: 0;
  margin-bottom: 1rem;
  font-size: 1.4rem;
`

const NameHeader = styled.h1`
  font-size: 3.5rem;
  margin-bottom: 0;
`

const NavLink = styled(Link)`
  color: #EF6830;
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
  position: relative;

  ::after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    transform-origin: bottom right;
    transition: transform 0.4s cubic-bezier(0.86, 0, 0.07, 1);
  }

  :hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
`
const Avatar = styled.img`
  color: transparent;
  width: 100px;
  height: 100px;
  object-fit: cover;
  text-align: cover;
  max-width: 100%;
  border-radius: 50%;
`

const LandingBio = () => (
  <StaticQuery
    query={graphql`
      query LandingSiteTitleQuery {
        site {
          siteMetadata {
            title
            subtitle
          }
        }
      }
    `}
    render={data => (
      <OuterContainer>
        <Container>
          <Avatar alt="Carl Venezia" src={Headshot} />
          <NameHeader>{data.site.siteMetadata.title}</NameHeader>
          <Description>full stack <NavLink to="/software">web developer</NavLink> and <NavLink to="/marketing">growth hacker</NavLink></Description>
          {/* <Description>Boston-bred, systems focused, taco-loving hard worker.</Description>
          <Description>Currently seeking work! Reach out here</Description> */}
        </Container>
      </OuterContainer>
    )}
  />
)

NameHeader.propTypes = {
  siteTitle: PropTypes.string,
  subtitle: PropTypes.string,
}

NameHeader.defaultProps = {
  siteTitle: ``,
  subtitle: ``,
}

export default LandingBio
